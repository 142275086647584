.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    color: #333;
    margin-top: 7%; 
    margin-bottom: 2%;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .contact-item {
    text-align: center;
    margin: 10px;
  }
  
  .contact-icon {
    font-size: 20px;
    color: #8d1d19;
    margin-bottom: 5px;
  }
  
  .contact-item h3 {
    margin: 5px 0;
    font-weight: bold;
  }
  
  .contact-item p {
    font-size: 14px;
    color: #555;
  }
  
  .contact-form {
    max-width: 500px;
    width: 100%;
    padding: 20px;
  }
  
  .contact-form h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .form-group label span {
    color: #8d1d19;
    font-size: 12px;
    margin-left: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
  }
  
  textarea {
    resize: none;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  textarea:focus {
    outline: none;
    border-color: #8d1d19;
  }
  
  .submit-button {
    display: block;
    width: 100px;
    margin: 0 auto;
    padding: 10px 15px;
    background-color: #8d1d19;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .submit-button:hover {
    background-color: #722018;
  }
  