.header-modelado3d-container {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  margin-top: 6%;
}
.services {
  font-family: Arial, sans-serif;
  color: #858585;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 7%;
  font-weight: bold;
}

.text-content {
  flex: 1;
}

.text-content h1 {
  font-size: 4em;
  color: #666;
  margin: 0;
  margin-bottom: 5%;
}

.underline {
  width: 50px;
  height: 3px;
  background-color: #8b0000; /* Color rojo oscuro */
  margin: 8px 0;
}

.text-content p {
  font-size: 1em;
  color: #666;
  line-height: 1.5;
  margin: 0;
  margin-top: 6%;
  text-align: justify;
}

.header-image {
  width: 250px;
  border-radius: 8px;
  margin-top: 0; /* Elimina el margen superior */
  align-self: flex-end; /* Alinea la imagen con el inicio del texto */
}
