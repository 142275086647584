body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-size: 100% 100vh;
  background-attachment: fixed;
  color: #ffffff; /* Color del texto */
  height: 100vh;
  position: relative;
}


header, footer {
  background-color: #ffffff; /* Color de fondo para el header y footer */
  color: #ffffff; /* Color del texto en el header y footer */
  text-align: center;
  padding: 1rem 0;
}

header nav a {
  color: #ffffff; /* Color de los enlaces en el header */
  text-decoration: none;
  margin: 0 15px;
}

header nav a:hover {
  color: #6f1f2d; /* Color de hover en los enlaces */
}

main {
  padding: 2rem;
  background-color: rgb(255, 255, 255); /* Fondo semitransparente para el contenido principal */
  margin-bottom: 0; /* Espacio para el pie de página */
}

.container {
  max-width: 1080px;
  margin: 0 auto;
}