/* AboutUs.css */
.about-us {
  font-family: Arial, sans-serif;
  color: #858585;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 5%;
  min-height: 70vh; /* Asegura que ocupe toda la altura de la ventana */
}

.title {
  font-size: 2.5em;
  color: #666;
  font-weight: bold;
  position: relative;
  margin-bottom: 30px;
}

.title::after {
  content: "";
  width: 50px;
  height: 3px;
  background-color: #a00;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.description {
  font-weight: bold;
  color: #858585;
  font-size: 1.1em;
  text-align: justify;
}

.section {
  margin-bottom: 40px; /* Incrementa el margen entre secciones */
}

.section h2 {
  font-size: 2em; /* Aumenta el tamaño de los subtítulos */
  color: #666;
  margin: 0 0 15px 0; /* Añade un margen debajo del subtítulo */
  text-align: left; /* Alinea los subtítulos a la izquierda */
}

.section p {
  font-weight: bold;
  font-size: 1.1em; /* Aumenta el tamaño del contenido */
  color: #858585;
  line-height: 1.6; /* Mejora la legibilidad */
  margin-bottom: 15px;
  text-align: justify;
}

.section ul {
  list-style-type: disc;
  padding-left: 20px;
  color: #858585;
  font-weight: bold;
  margin-bottom: 15px; /* Espaciado extra para listas */
}

.section ul li {
  margin-bottom: 8px;
}
