/* Estilos para el MainContent */
#main-content {
  margin-top: 10px;
  width: 100%;
  height: auto;
  padding: 0;
  z-index: 1;
}

/* Títulos */
h2 {
  font-size: 4vw; /* Cambiado a vw para responsividad */
  /* Tamaño grande para los títulos */
  font-weight: bold;
  color: #ffffff;
  /* Asegúrate que el color sea legible */
  text-align: center;
  margin-right: 20%;
  margin-left: 20%;
}

/* Texto animado debajo de los títulos */
.animated-text {
  font-size: 1.1vw;
  width: 100%; /* Asegura que el texto ocupe el 100% del contenedor */
  text-align: center;
  overflow-wrap: break-word; /* Asegura que el texto no se salga por el ancho */
}
.r{
  margin-top: 20px;
}
.boxTleft{
  box-sizing: border-box; /* Incluye el padding dentro del tamaño del contenedor */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  overflow: hidden; /* Recorta cualquier contenido que se salga del área visible */
  position: absolute;
  inset: 10% 5% 10% 20%;
}
.boxtext{
  box-sizing: border-box; /* Incluye el padding dentro del tamaño del contenedor */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  overflow: hidden; /* Recorta cualquier contenido que se salga del área visible */
  position: absolute;
  inset: 10% 15% 5% 3%;
}

/* Contenedor padre */
div .moduletable.hello.span6 {
  line-height: 40px;
  position: relative;
}
.titleL, .titleR {
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 3%;
}

.parent {
  filter: url('#goo');
  width: calc(100% + 40px); /* Aumenta el ancho del contenedor */
  position: fixed;
  left: -20px; /* Mueve el contenedor hacia la izquierda */
  right: -20px; /* Mueve el contenedor hacia la derecha */
  overflow: hidden; /* Asegúrate de que el contenido que sobresale no sea visible */
  z-index: 1;
}
/* Cuadro izquierdo (Bienvenidos) más pequeño */
.left-box {
  position: absolute;
  margin-top: 5%;
  width: 47%;
  /* Hazlo más estrecho que la caja derecha */
  height: 130px;
  /* Menor altura */
  background-color: #961414;
  /* Fondo naranja */
  clip-path: polygon(0% 0%, 100% 0%, 84% 100%, 0% 100%);
  /* Mantén la forma de romboide */
  top: 0px;
  /* Ajusta la posición vertical */
  left: 0%;
  /* Ajusta la posición horizontal */
  padding: 20px;
  /* Espacio interno para el texto */
  display: flex;
  flex-direction: column;
  z-index: 2;
}


/* Cuadro derecho (Acerca de Nosotros) más grande */
.right-box {
  position: absolute;
  top: -230px;
  width: 60%;
  /* Más ancho */
  height: 300px;
  /* Más alto */
  background-color: #1c202b;
  /* Fondo gris oscuro */
  clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 0% 100%);
  left: 40%;
  /* Ajusta la posición horizontal para solaparse */
  padding: 20px;
  /* Espacio interno para el texto */
  display: flex;
  flex-direction: column;
  z-index: 1;
  /* Esta caja queda por debajo */
}

/* Ajuste para las cajas dentro de row-fluid */
div .row-fluid {
  display: flow-root;
  position: relative;
  padding: 0px;
  height: 450px;
  /* Espacio para evitar que se solapen fuera del contenedor */
}

.left-box,
.right-box {
  transition: all 0.3s ease-in-out;
  /* Transiciones suaves */
}

.left-box:hover,
.right-box:hover {
  transform: scale(1.01);
  /* Efecto de ampliación en hover */
}


/* Media Queries */
@media (max-width: 768px) {
  .animated-text {
    font-size: 1.5vw;
    width: 100%; /* Asegura que el texto ocupe el 100% del contenedor */
    text-align: center;
    overflow-wrap: break-word; /* Asegura que el texto no se salga por el ancho */
  }
  /* Cuadro izquierdo (Bienvenidos) más pequeño */
.left-box {
  position: absolute;
  margin-top: 5%;
  width: 50%;
  /* Hazlo más estrecho que la caja derecha */
  height: 130px;
  /* Menor altura */
  background-color: #961414;
  /* Fondo naranja */
  clip-path: polygon(0% 0%, 100% 0%, 84% 100%, 0% 100%);
  /* Mantén la forma de romboide */
  top: 0px;
  /* Ajusta la posición vertical */
  left: 0%;
  /* Ajusta la posición horizontal */
  padding: 20px;
  /* Espacio interno para el texto */
  display: flex;
  flex-direction: column;
  z-index: 2;
}


/* Cuadro derecho (Acerca de Nosotros) más grande */
.right-box {
  position: absolute;
  top: -260px;
  width: 50%;
  /* Más ancho */
  height: 350px;
  /* Más alto */
  background-color: #1c202b;
  /* Fondo gris oscuro */
  clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%);
  left: 45%;
  /* Ajusta la posición horizontal para solaparse */
  padding: 20px;
  /* Espacio interno para el texto */
  display: flex;
  flex-direction: column;
  z-index: 1;
  /* Esta caja queda por debajo */
}
.left-box:hover,
.right-box:hover {
  transform: scale(1.01);
  /* Efecto de ampliación en hover */
}
}
/* Media Queries */
@media (max-width: 480px) {
  .animated-text {
    font-size: 2vw;
    width: 100%; /* Asegura que el texto ocupe el 100% del contenedor */
    text-align: center;
    overflow-wrap: break-word; /* Asegura que el texto no se salga por el ancho */
  }
  /* Cuadro izquierdo (Bienvenidos) más pequeño */
.left-box {
  position: absolute;
  margin-top: 20%;
  width: 45%;
  /* Hazlo más estrecho que la caja derecha */
  height: 130px;
  /* Menor altura */
  background-color: #961414;
  /* Fondo naranja */
  clip-path: polygon(0% 0%, 100% 0%, 84% 100%, 0% 100%);
  /* Mantén la forma de romboide */
  top: 0px;
  /* Ajusta la posición vertical */
  left: 0%;
  /* Ajusta la posición horizontal */
  padding: 20px;
  /* Espacio interno para el texto */
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.r{
  margin-top: 0px;
}
.titleL, .titleR {
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 30px;
}

/* Cuadro derecho (Acerca de Nosotros) más grande */
.right-box {
  position: absolute;
  top: -260px;
  width: 50%;
  /* Más ancho */
  height: 350px;
  /* Más alto */
  background-color: #1c202b;
  /* Fondo gris oscuro */
  clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%);
  left: 45%;
  /* Ajusta la posición horizontal para solaparse */
  padding: 20px;
  /* Espacio interno para el texto */
  display: flex;
  flex-direction: column;
  z-index: 1;
  /* Esta caja queda por debajo */
}
.boxTleft{
  box-sizing: border-box; /* Incluye el padding dentro del tamaño del contenedor */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  overflow: hidden; /* Recorta cualquier contenido que se salga del área visible */
  position: absolute;
  inset: -4% 15% 5% 23%;
}
.boxtext{
  box-sizing: border-box; /* Incluye el padding dentro del tamaño del contenedor */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  overflow: hidden; /* Recorta cualquier contenido que se salga del área visible */
  position: absolute;
  inset: -10% 15% 5% 3%;
}
.left-box:hover,
.right-box:hover {
  transform: scale(1.01);
  /* Efecto de ampliación en hover */
}


}