.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    margin: 0 auto;
  }
  
  .lineas-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px; /* Espacio entre las líneas y el texto */
  }
  
  .linea-roja {
    width: 20%;
    height: 4px;
    background-color: #961414; /* Rojo oscuro */
  }
  
  .texto-box {
    text-align: center;
    margin-bottom: 3%;
  }
  
  .texto {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    font-size: 1vw;
    line-height: 1.6;
    color: black;
    margin: 0;
  }
  