/* Estilo para los enlaces del header */
.header nav a {
  color: #000000; /* Color negro */
  text-decoration: none; /* Elimina el subrayado predeterminado */
  padding: 0 10px; /* Espaciado entre enlaces */
}
/* Estilo para los separadores '|' */
.header nav {
  color: #000000; /* Color negro para los separadores */
}

.header img {
  height: auto; /* Mantiene la proporción */
  max-height: 80px; /* Limita la altura máxima del logo */
}
/* Estilo del header */
.header {
  position: fixed;  /* Fija el header */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;    
  background-color: #ffffff;
  padding: 15px 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Ajuste del contenido del cuerpo */
.body-content {
  margin-top: 70px; /* Ajusta el margen superior para evitar que el contenido se superponga con el header */
}
/* Media queries para hacer el diseño responsive */

/* Para pantallas pequeñas (teléfonos) */
@media only screen and (max-width: 600px) {
  .logo {
    font-size: 1.5rem;  /* Ajusta el tamaño del logo */
  }
  .header {
    padding: 10px 15px;  /* Reduce el padding del header */
  }
  .body-content {
    margin-top: 60px;  /* Ajusta el margen superior para pantallas pequeñas */
  }
}

/* Para pantallas medianas (tabletas) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .logo {
    font-size: 2rem;  /* Ajusta el tamaño del logo */
  }
  .header {
    padding: 12px 18px;  /* Ajusta el padding del header */
  }
}

/* Para pantallas grandes (laptops y desktops) */
@media only screen and (min-width: 1025px) {
  .logo {
    font-size: 5rem;  /* Mantiene el tamaño grande del logo */
  }
}
