.romboide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #a60000; /* Color rojo */
  color: white;
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
  padding: 20px 30px;
  border-radius: 10px; /* Bordes redondeados */
  transform: skew(-40deg); /* Efecto romboide */
  width: 200px;
  height: 80px;
  position: relative; /* Habilita el control de capas */
  z-index: 2; /* Aparece por encima */
  left: -60px;
}
element.style {
  display: flex
;
  justify-content: center;
  align-items: center;
  height: 30vh;
  position: relative;
}
.romboide p {
  margin: 0;
  transform: skew(40deg); /* Corregir inclinación del texto */
}

.romboideBack {
  border-radius: 10px; /* Bordes redondeados */
  transform: skew(-40deg); /* Efecto romboide */
  background-color: #b00000; /* Color más oscuro para contraste */
  position: relative;
  z-index: 1; /* Aparece por debajo */
  top: -90px; /* Ajusta la posición vertical */
  left: 40px; /* Ajusta la posición horizontal */
}
.romboide2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #a60000; /* Color rojo */
  color: white;
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
  padding: 20px 30px;
  border-radius: 10px; /* Bordes redondeados */
  font-weight: bold; /* Negrita para el texto */
  transform: skew(-40deg); /* Efecto romboide */
  width: 400px;
  height: 100px;
  position: absolute; /* Control de posición */
  right: -350px; /* Alineado a la derecha */
  top: 50%; /* Centrado verticalmente */
  transform: translateY(-50%) skew(-40deg); /* Ajustar posición y romboide */
  z-index: 2;
}

.romboide2 p {
  margin: 0;
  transform: skew(40deg); /* Corrige la inclinación del texto */
}

.romboide-contenedor {
  filter: url("#cardInfo"); /* Aplica el filtro SVG */
  width: 400px; /* Ancho del romboide */
  height: 400px; /* Alto del romboide */
  position: absolute; /* Controla la posición */
  right: 50px; /* Alineado a la derecha */
  top: 50%; /* Centrado verticalmente */
  transform: translateY(-60%) skew(-20deg); /* Aplica inclinación al contenedor */
  clip-path: polygon(26% 15%, 94% 14%, 75% 85%, 5% 86%); /* Forma del romboide */
  z-index: 2; /* Control de superposición */
  overflow: hidden; /* Asegura que el contenido no sobresalga */
}

.romboide-imagen {
  overflow: visible;
  transform: skew(20deg); /* Corrige la inclinación del texto */
  width: 100%; /* Imagen ocupa todo el contenedor */
  height: 100%; /* Imagen ocupa todo el contenedor */
  background-size: cover; /* Escala proporcionalmente */
  background-position: center; /* Centra la imagen */
}
