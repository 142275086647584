/* Estilo general para el footer */
.custom-footer {
  position: relative;
  bottom: 0; 
  background-color: rgb(30, 32, 41); /* Fondo oscuro */
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  color: white;
  width: 90%; /* Asegura que ocupe todo el ancho */
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 0px solid black;
  border-radius: 10px;
  transform: skew(-40deg); /* Hace que el contenedor adopte forma de romboide */
  margin-top: auto; /* Empuja el footer hacia el final */
  height: 2vh;
  z-index: 2; /* Aparece por encima */
}

.footer-content {
  transform: skew(40deg); /* Corrige el skew aplicado al texto dentro del footer */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  gap: 40vh;
}
.contact-infoF, .email-info {
  display: flex;
  align-items: center; /* Centra verticalmente los íconos y texto */
  justify-content: flex-start; /* Alinea los elementos horizontalmente */
  flex-grow: 1; /* Distribuye espacio proporcionalmente si es necesario */
}

.contact-infoF .icon,
.email-info .icon {
  margin-right: 8px;
  font-size: 1.5em;
  color: #4caf50; /* Verde para íconos */
}



.diva {
  filter: url("#butonB");
}

/* Borde superior e inferior en forma diagonal */
.custom-footer:before,
.custom-footer:after {
  filter: url("#butonB");
  content: "";
  position: absolute;
  width: 90px; /* Aumentar el ancho si es necesario */
  height: 6vh; /* Aumentar el alto si es necesario */
  background-color: #a71930; /* Color rojo para los bordes */
}

/* Ajuste para el borde superior (más arriba y a la izquierda) */
.custom-footer:before {
  top: -15px; /* Más arriba */
  left: -30px; /* Más a la izquierda */
  transform: skewY(0deg); /* Mantén la inclinación */
}

/* Ajuste para el borde inferior (más abajo y a la derecha) */
.custom-footer:after {
  bottom: -15px; /* Más abajo */
  right: -30px; /* Más a la derecha */
  width: 90px;
  height: 6vh; /* Aumentar el alto si es necesario */
  transform: skewY(0deg); /* Mantén la inclinación */
}
