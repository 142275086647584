/* Estilos para el carrusel de video */
.video-carousel {
  margin-top: 135px;
    position: relative;
    width: 100%;
    height: 666px; /* Ajusta el tamaño del carrusel de video */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0); /* Fondo transparente */
  }
  
  /* Estilo del video */
  video {
    width: 1920px; /* Ajusta el ancho que necesites */
    height: 666px; /* Coincide con la altura del contenedor */
    object-fit: cover; /* El video se ajusta dentro del contenedor */
    animation: fadeIn 1s ease-in-out;
    z-index: 0; /* Asegura que el video esté detrás de los botones (si los hay) */
  }
  
  /* Estilos para los botones de navegación, si decides añadirlos */
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  
  .carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Animación para el desvanecimiento del video */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  