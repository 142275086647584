.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.slides {
    display: grid;
    > .slide {
      grid-area: 1 / -1;
    }
  
    > button {
      appearance: none;
      background: transparent;
      border: none;
      color: white;
      position: absolute;
      font-size: 5rem;
      width: 5px;
      height: 5rem;
      top: 30%;
      transition: opacity 0.3s;
      opacity: 0.7;
      z-index: 5;
  
      &:hover {
        opacity: 1;
      }
  
      &:focus {
        outline: none;
      }
  
      &:first-child {
        left: 10%;
      }
      &:last-child {
        right: 10%;
      }
    }
  }
  .slideContent {
    
    margin-left: 30px;
    width: 30vw;
    height: 40vw;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: transform 0.5s ease-in-out;
    opacity: 0.7;
  
    display: grid;
    align-content: center;
  
    transform-style: preserve-3d;
    transform: perspective(1000px) translateX(calc(100% * var(--offset)))
      rotateY(calc(-45deg * var(--dir)));
  }
  

  .container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra horizontalmente */
    justify-content: center; /* Centra verticalmente si es necesario */
    width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
    position: relative; /* Contexto para elementos posicionados */
    
}


.my-button {
  margin-bottom: 20px; /* Espacio entre el botón y el carrusel */
}

.slides {
  display: grid;
  justify-content: center;
  position: relative;
  width: 90%; /* Ajuste para pantallas pequeñas */
  max-width: 1200px; /* Limite para pantallas grandes */
  height: 50vw; /* Ajuste según el ancho de la pantalla */
  max-height: 400px;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 5px;
  overflow: hidden;
}
.slideContent {
  width: 100%; /* Asegúrate de que el contenido del slide ocupe el ancho total */
  height: 100%; /* Asegúrate de que el contenido del slide ocupe la altura total */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  opacity: 0.7;
  transform-style: preserve-3d;
  transform: perspective(1000px) translateX(calc(100% * var(--offset)))
    rotateY(calc(-45deg * var(--dir)));
}

.slideBackground {
  position: absolute; /* Cambiar a absolute */
  top: 0;
  left: 0; /* Cambiar a 0 para ajustarse al contenedor */
  right: 0; /* Cambiar a 0 para ajustarse al contenedor */
  bottom: 0; /* Cambiar a 0 para ajustarse al contenedor */
  background-size: contain;
  background-position: center center;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s linear, transform 0.3s ease-in-out;
  pointer-events: none;
  transform: translateX(calc(10% * var(--dir)));
}

.slideContentInner {
    transform-style: preserve-3d;
    transform: translateZ(2rem);
    transition: opacity 0.3s linear;
    text-shadow: 0 0.1rem 1rem #000;
    opacity: 0;
  
    .slideSubtitle,
    .slideTitle {
      font-size: 2rem;
      font-weight: normal;
      letter-spacing: 0.2ch;
      text-transform: uppercase;
      margin: 0;
    }
  
    .slideSubtitle::before {
      content: "— ";
    }
  
    .slideDescription {
      margin: 0;
      font-size: 0.8rem;
      letter-spacing: 0.2ch;
    }
  }
  .slide[data-active] {
    z-index: 2;
    pointer-events: auto;
  
    .slideBackground {
      opacity: 0.2;
      transform: none;
    }
  
    .slideContentInner {
      opacity: 1;
    }
  
    .slideContent {
      --x: calc(var(--px) - 0.5);
      --y: calc(var(--py) - 0.5);
      opacity: 1;
  
      transform: perspective(1000px);
  
      &:hover {
        transition: none;
        transform: perspective(1000px) rotateY(calc(var(--x) * 45deg))
          rotateX(calc(var(--y) * -45deg));
      }
    }
  }
  body {
    overflow-x: hidden; /* Oculta la barra de desplazamiento horizontal */
  }
  
  