.romB{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1c1c26; /* Color rojo */
  color: white;
  font-family: Arial, sans-serif;
  font-size: 24px;
  text-align: center;
  line-height: 1.5;
  padding: 20px 30px;
  border-radius: 10px; /* Bordes redondeados */
  transform: skew(-50deg); /* Efecto romboide */
  width: 60px;
  height: 20px;
  position: relative; /* Habilita el control de capas */
  z-index: 1; /* Aparece por encima */
  left: -20px;
}
.romA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1c1c26; /* Color rojo */
  color: white;
  font-family: Arial, sans-serif;
  font-size: 24px;
  text-align: center;
  line-height: 1.5;
  padding: 20px 30px;
  border-radius: 10px; /* Bordes redondeados */
  font-weight: bold; /* Negrita para el texto */
  transform: skew(-50deg); /* Efecto romboide */
  width: 60px;
  height: 20px;
  position: absolute; /* Control de posición */
  right: -20px; /* Alineado a la derecha */
  top: 20%; /* Centrado verticalmente */
  z-index: 1;
}
.custom-button {
  border-radius: 10px; /* Bordes redondeados */
  transform: skew(-50deg); /* Efecto romboide */
  background-color: #8b0000;
  position: relative;
  z-index: 1; /* Aparece por debajo */
  top: -50px; /* Ajusta la posición vertical */
  border: none;
  cursor: pointer;
  z-index: 2;
}
.custom-button p{
  transform: skew(50deg); /* Corrige la inclinación del texto */
  color: white;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  font-size: clamp(0.7rem, 0.9vw, 1.2rem); /* Tamaño de fuente responsivo */
}
.custom-button:hover {
  background-color: #a30000;
  cursor: pointer;
  z-index: 3;
}