.section-with-image-and-list-container {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
  }
  
  .section-image {
    width: 400px;
    border-radius: 8px;
  }
  
  .text-content {
    flex: 1;
  }
  
  .text-content h2 {
    font-size: 1.8em;
    color: #666;
    margin: 0;
  }
  
  .text-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .text-content li {
    font-size: 1em;
    color: #666;
    line-height: 1.5;
    margin-bottom: 10px;
    text-align: justify;
  }
  