/* From Uiverse.io by eslam-hany */ 
.card {
  position: relative;
  width: 11.875em;
  height: 16.5em;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 120ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../images/diagrama.png') no-repeat center center; /* Ruta a tu imagen */
  background-size: cover;
  padding: 0.5em;
  padding-bottom: 3.4em;
  color: rgb(226, 224, 224);
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #3b1919, -1px 1px 0 #421010, 1px 1px 0 #4d0909; /* Borde negro alrededor del texto */
  overflow: hidden;
  text-align: center;
}

  
  .card::before,
  .card::after {
    position: absolute;
    content: "";
    width: 20%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    background-color: #1c222a;
    transition: all 0.5s;
  }
  
  .card::before {
    top: 0;
    right: 0;
    border-radius: 0 15px 0 100%;
  }
  
  .card::after {
    bottom: 0;
    left: 0;
    border-radius: 0 100%  0 15px;
  }
  
  .card:hover::before,
  .card:hover:after {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    transition: all 0.5s;
  }
  
  .card:hover:after {
    text-align: center; /* Centrar texto en varias líneas */
    align-items: center;
    display: flex; /* Usar flexbox para centrar el texto */
    justify-content: center;
    font-size: 2vh;
    content: attr(data-description); /* Toma el contenido dinámicamente del atributo */  }
  /* Estilos para el grid */
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columnas */
    gap: 20px; /* Espacio entre las tarjetas */
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    margin: 130px auto 100px; /* Márgenes superior/inferior y centrado automático */
    max-width: 800px; /* Ancho máximo del grid */
}
